
import boldBiConfig from "./config";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"

const bBHelper = {

    generateEmbedUrl: ()=>{
        
        return getBaseUrlDelviumBackend() + boldBiConfig.EMBED_ENDPOINT ;
    },
}

export default bBHelper;

